import React, { useState } from "react"
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { AiOutlineBarcode } from 'react-icons/ai'
import { Icon } from '@iconify/react';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { FormControl, FormLabel, InputLabel, MenuItem, Select } from "@material-ui/core";
import SectionMain from "../../layout/SectionMain";
import RenderInputsV4 from "../checkout-1.5.4/forms/RenderInputsV4";
import toCurrency from "../../core/toCurrency";
import { useTranslation } from "../IntlContext/IntlContext";
import RenderPayzenForm from "../checkout-1.5.4/forms/PayzenForm";

const PaymentMethodsList = ({
    availablMethods = ['credit_card', 'boleto', 'pix'],
    onSelect = () => null,
    selectedMethod = 'credit_card',
    formikprops = {},
    checkout = {},
    totalAmount = 0,
    customer = {},
    couponId = null,
    setSuccessPage = () => null
}) => {
    const t = useTranslation()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const RenderIcon = ({ method }) => {
        switch (method) {
            case 'credit_card':
                return <CreditCardIcon style={{ fontSize: 30 }} />
            case 'boleto':
                return <AiOutlineBarcode style={{ fontSize: 30 }} />
            case 'pix':
                return <Icon icon="ic:baseline-pix" style={{ fontSize: '30px' }} />

            default:
                return null
        }
        return null
    }

    return (
        <>
            <div className="flex-row" style={{ flexWrap: 'wrap' }}>

                {availablMethods.includes('credit_card') && <div role='button' className="flex-row center-b" onClick={() => onSelect('credit_card')} style={{ flex: 1, margin: 10, border: '1px solid #e5e5e5', padding: 10, borderRadius: 10, cursor: "pointer", backgroundColor: selectedMethod === 'credit_card' ? "#e6e6e6" : "", }}>
                    <RenderIcon method={'credit_card'} />
                    <span style={{ marginLeft: 5 }}>
                        {t.titles[`payment_method.${'credit_card'}`]}
                    </span>
                </div>}

                {availablMethods.includes('pix') && <div role='button' className="flex-row center-b" onClick={() => onSelect('pix')} style={{ flex: 1, margin: 10, border: '1px solid #e5e5e5', padding: 10, borderRadius: 10, cursor: "pointer", backgroundColor: selectedMethod === 'pix' ? "#e6e6e6" : "", }}>
                    <RenderIcon method={'pix'} />
                    <span style={{ marginLeft: 5 }}>
                        {t.titles[`payment_method.${'pix'}`]}
                    </span>
                </div>
                }
                {availablMethods.includes('boleto') && <div role='button' className="flex-row center-b" onClick={() => onSelect('boleto')} style={{ flex: 1, margin: 10, border: '1px solid #e5e5e5', padding: 10, borderRadius: 10, cursor: "pointer", backgroundColor: selectedMethod === 'boleto' ? "#e6e6e6" : "", }}>
                    <RenderIcon method={'boleto'} />
                    <span style={{ marginLeft: 5 }}>
                        {t.titles[`payment_method.${'boleto'}`]}
                    </span>
                </div>}
            </div>
            {selectedMethod === "credit_card" &&
                <>
                    {checkout.use_form_token ? (
                        <div className="flex-row center-a">
                            <div id="form-payzen" />
                            <RenderPayzenForm
                                gatewayConfig={
                                    checkout.gateway_config
                                }
                                setSuccess={
                                    (clientAnswer) => setSuccessPage({
                                        type: "credit_card", customer, data: clientAnswer
                                    })
                                }
                                subscriptionId={
                                    customer.subscription_id
                                }
                                couponId={
                                    couponId
                                }
                                setIsSubmitting={setIsSubmitting}
                            />
                        </div>
                    ) : (
                        <>
                            {checkout.checkout_config.installment_enabled &&
                                <div key={`row-`} className="flex-row xs-wrap">
                                    <div key={`input`} className="flex-col" style={{ width: '100%', margin: 10 }}>
                                        <FormControl variant="outlined" >
                                            <InputLabel id="demo-simple-select-required-label">{t.forms.installments}</InputLabel>
                                            <Select label={t.forms.installments} defaultValue={1} name="installments" value={formikprops.values.installments} onChange={formikprops.handleChange} fullWidth variant="outlined">
                                                {Array(checkout.checkout_config.installments).fill(1).map((installment, index) => (
                                                    <MenuItem value={installment + index}>
                                                        <SectionMain position="space-between">
                                                            <div>
                                                                {`${installment + index}x`}
                                                            </div>
                                                            <FormLabel component="legend">
                                                                {t.generics.in}{" "}
                                                                {
                                                                    installment + index
                                                                }

                                                                x{" "}
                                                                {toCurrency(
                                                                    (totalAmount / (installment + index)).toFixed(0),
                                                                    "BRL"
                                                                )}
                                                            </FormLabel>
                                                        </SectionMain>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            }
                            <RenderInputsV4
                                formikprops={formikprops}
                                checkoutConfig={checkout}
                                rows={[
                                    ['cc_number'],
                                    ['cc_expiry_date', 'cvv'],
                                    ['cc_cardholder'],
                                ]} />
                        </>
                    )}
                </>}

            {['boleto'].includes(selectedMethod) &&
                <>
                    {!checkout.checkout_config?.document_enabled && (
                        <>
                            <span style={{ marginLeft: 10 }}>
                                {t.forms.document_type}:
                            </span>
                            <RenderInputsV4
                                // disabledInputs={disabledInputsBoleto()}
                                formikprops={formikprops}
                                checkoutConfig={checkout}
                                rows={[
                                    ['document_type', 'document_number']
                                ]} />
                        </>
                    )}

                    {!checkout.checkout_config?.billing_address_enabled && (
                        <>
                            <span style={{ marginLeft: 10 }}>
                                {t.titles['billing_address.form']}:
                            </span>
                            <RenderInputsV4
                                formikprops={formikprops}
                                checkoutConfig={checkout}
                                rows={[
                                    ['billing_address_postal_code'],
                                    ['billing_address_street1'],
                                    ['billing_address_number', 'billing_address_district'],
                                    ['billing_address_city', 'billing_address_state_br'],
                                    ['billing_address_complement']
                                ]} />

                        </>

                    )}
                </>
            }

            {['pix'].includes(selectedMethod) &&
                <>
                    {!checkout.checkout_config?.document_enabled && (
                        <>
                            <span style={{ marginLeft: 10 }}>
                                {t.forms.document_type}:
                            </span>
                            <RenderInputsV4
                                // disabledInputs={disabledInputsBoleto()}
                                formikprops={formikprops}
                                checkoutConfig={checkout}
                                rows={[
                                    ['document_type', 'document_number']
                                ]} />
                        </>
                    )}
                </>
            }
        </>
    )

}

export default PaymentMethodsList