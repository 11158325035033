/* eslint-disable */
import React, { useState, useRef, useEffect, useContext } from 'react'
import Success from '../components/Success'
import TextInput from '../components/checkout-1.5.4/forms/TextInput'
import WrapperLayout from '../layout/WrapperLayout'
import SectionMain from '../layout/SectionMain'
import KRGlue from '@lyracom/embedded-form-glue'
import getLocale from '../locale'
import StepTitle from '../components/checkout-1.5.4/forms/StepTitle'
import Title, { TitleSmall } from '../components/checkout-1.5.4/forms/Title'
import {
	Grid,
	Hidden,
	FormControlLabel,
	Checkbox,
	ListItem,
	Button,
	withStyles,
	IconButton,
	CircularProgress,
	Container,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core'
import { string, object, mixed, array } from 'yup' // for only what you need
import { Formik, ErrorMessage } from 'formik'
import RenderInputsV2 from '../components/checkout-1.5.4/forms/RenderInputsV2'
import AnimatedView from '../components/checkout-1.5.4/AnimatedView'
import { backgroundColor } from '../layout/color'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import api, { billService } from '../core/api'
import toCurrency from '../core/toCurrency'
import { useConfirmation } from '../core/useConfirmation'
import { useTranslation } from '../components/IntlContext/IntlContext'
import { Lg, Md } from '../components/grids/Cols'
import { Error } from '../components/forms/FormContainer'
import SuccessPage from '../components/checkout-1.5.4/SuccessPage'
import RoomIcon from '@material-ui/icons/Room'
import HomeIcon from '@material-ui/icons/Home'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import PersonIcon from '@material-ui/icons/Person'
import ErrorCheckoutMessage from '../components/checkout-1.5.4/ErrorMessage'
import AppContext, { useAppContext } from '../components/AppContextCheckout'
import { lightOrDark } from '../utils/lightOrDark'
import ParticlesBg from 'particles-bg'
import renderKRForm from '../components/checkout-1.5.4/forms/PayzenForm'
import RenderPayzenForm from '../components/checkout-1.5.4/forms/PayzenForm'
import RenderInputsV3 from '../components/checkout-1.5.4/forms/RenderInputsV3'
import RenderInputsUI from '../components/checkout-1.5.4/forms/RenderInputsV3/RenderInputsUI'
import RenderInputsWrapper from '../components/checkout-1.5.4/forms/RenderInputsV3/RenderInputsWrapper'
import { handleLocaleChange } from '../locale/handleLocaleChange'

/**
 * 
 * @returns {import("../locale/locale").TranslationFunction} 
 */

let language = { BRA: 'pt-BR', FRA: 'fr' }

const execImmediate = (f) => f()

let next = {}
let stepNumbers = {
	one: 1,
	two: 2,
	tree: 3,
	four: 4
}

const checkout_config = {
	billing_address: true,
	country_code: true,
	document: true,
	email: true,
	first_name: true,
	last_name: true,
	mobile_number: true,
	shipping_address: true
}

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9'
		// marginBottom: 12
	}
}))(Tooltip)

const TestPage = () => {
	// Customer || Payment
	const { setAppState, payzenForm } = useAppContext(AppContext)

	const [ step, setStep ] = useState('customer')

	const [ menuCollapse, setMenuCollapse ] = useState({
		first: true,
		second: true,
		third: true,
		four: false
	})
	const [ enabledShippingAddress, setEnabledShippingAddress ] = useState(true)
	const [ company, setCompany ] = useState()
	const [ addressInputs, setAddressInputs ] = useState({
		billingAddressInputs: [ 'billing_address' ],
		shippingAddressInputs: [ 'shipping_address' ]
	})
	const [ checkout, setCheckout ] = useState('')
	const [ messageError, setMessageError ] = useState('')
	const [ loading, setLoading ] = useState(true)
	const [ planHeight, setPlanHeight ] = useState('')
	const [ planWidth, setPlanWidth ] = useState('')
	const t = useTranslation()
	const [ confirm, showConfirm ] = useConfirmation()
	const [ success, setSuccess ] = useState('')
	const [ validationSchema, setValidationSchema ] = useState(() => void 0)
	const [ defaultValues, setDefaultValues ] = useState({
		disabled_input: false,
		first_name: '',
		last_name: '',
		email: '',
		mobile_phone: '',
		country_code: '',
		document: { type: 'CPF', number: '' },
		shipping_address: {
			postal_code: '',
			street1: '',
			street2: '',
			number: '',
			state: '',
			district: '',
			city: '',
			country: '',
			complement: ''
		},
		billing_address: {
			postal_code: '',
			street1: '',
			street2: '',
			number: '',
			state: '',
			district: '',
			city: '',
			country: '',
			complement: ''
		},
		payment_mean: { card_number: '', expiry_date: '', cvv: '', customer_name: '', brand: '', subscription_id: '' },
		coupon: {
			coupon_code: '',
			coupon_enabled: false,
			coupon_error: ''
		}
	})

	const customerInputs = [ 'first_name', 'last_name', 'email', 'document', 'mobile_number', 'country_code' ]
	const paymentInputs = [ 'payment' ]

	const handleMenuCollapsable = (key) => {
		return setMenuCollapse({ ...menuCollapse, [key]: !menuCollapse[key] })
	}

	const checkValidationSchema = (checkout_config, country) => {
		const arrayCheckoutConfig = [
			{
				key: 'first_name',
				shape: {
					first_name: string().required('Preencha o campo nome.'),
					last_name: string().required('Preencha o campo sobrenome.'),
					email: string().email('Digite um e-mail válido').required('Preencha o campo de e-mail')
				}
			},
			// {
			// 	key: 'country_code',
			// 	shape: {
			// 		country_code: string().required('Preecha o campo país')
			// 	}
			// },
			{
				key: 'document',
				shape: {
					document: object().shape({
						type: string().required('Preencha o campo tipo.'),
						number: string().required('Preencha o campo número.')
					})
				}
			},
			{
				key: 'billing_address',
				shape: {
					billing_address: object().shape({
						postal_code: string().required('Preencha o campo cep.'),
						street1: string().required('Preencha o campo rua.'),
						number: string().required('Preencha o campo número.'),
						state: string().required('Preencha o campo estado.'),
						district: string().required('Preencha o campo bairro.'),
						city: string().required('Preencha o campo cidade.')
						// country: string().required('Preencha o campo país.')
					})
				}
			},
			{
				key: 'shipping_address',
				shape: {
					shipping_address: object().shape({
						postal_code: string().required('Preencha o campo cep.'),
						street1: string().required('Preencha o campo rua.'),
						number: string().required('Preencha o campo número.'),
						state: string().required('Preencha o campo estado.'),
						district: string().required('Preencha o campo bairro.'),
						city: string().required('Preencha o campo cidade.')
						// country: string().required('Preencha o campo país.')
					})
				}
			},
			{
				key: 'billing_address.fr',
				shape: {
					billing_address: object().shape({
						postal_code: string().required('Preencha o campo cep.'),
						street1: string().required('Preencha o campo rua.'),
						street2: string().required('Preencha o campo rua.'),
						city: string().required('Preencha o campo cidade.')
						// country: string().required('Preencha o campo país.')
					})
				}
			},
			{
				key: 'shipping_address.fr',
				shape: {
					shipping_address: object().shape({
						postal_code: string().required('Preencha o campo cep.'),
						street1: string().required('Preencha o campo rua.'),
						street2: string().required('Preencha o campo rua.'),
						city: string().required('Preencha o campo cidade.')
						// country: string().required('Preencha o campo país.')
					})
				}
			}
		]
		arrayCheckoutConfig.map(({ key, shape }, i) => {
			if (checkout_config[key] === true) {
				if (country === 'FRA') {
					switch (key) {
						case 'billing_address':
							next = { ...next, ...arrayCheckoutConfig[4].shape }
							break

						case 'shipping_address':
							next = { ...next, ...arrayCheckoutConfig[5].shape }
							break

						default:
							next = { ...next, ...arrayCheckoutConfig[i].shape }
							break
					}
				}
				console.log(next)

				setValidationSchema(
					object().shape({
						first_name: string().required('Preencha o campo nome.'),
						last_name: string().required('Preencha o campo sobrenome.'),
						email: string().email('Digite um e-mail válido').required('Preencha o campo de e-mail'),
						...next
					})
				)
			}
		})
	}

	const checkInitialFormStep = (checkoutResponse, country) => {
		if (checkoutResponse && checkoutResponse.customer) {
			setMenuCollapse({ first: false, second: false, third: false, four: true })
			setStep('payment')
			setDefaultValues({
				...defaultValues,
				...checkoutResponse.customer,
				mobile_phone: checkoutResponse.customer.mobile_number,
				payment_mean: {
					...defaultValues.payment_mean,
					customer_id: checkoutResponse.customer.customer_id
				},
				disabled_input: true
			})
		}

		checkValidationSchema(checkoutResponse.checkout_config, country)
	}

	const fetchCompanyConfig = async () => {
		try {
			await api.get('/company-config').then((res) => {
				const company = res.data

				setCompany(company)

				// setAppState({ locale: locale })
			})
		} catch (e) {
			// if (e.message.includes('Network')) {
			// 	setResponse('Ops, Erro de conexão, por favor recarregue a pagina.')
			// }
			console.log({ e })
		}
	}

	const fetchCheckoutInfo = async () => {
		try {
			setLoading(true)
			const qs = new URLSearchParams(window.location.search)
			const hash = qs.get('hash')
			await billService
				.get(`/checkout?hash=${hash}`)
				.then((res) => {
					const { data: checkout } = res

					checkInitialFormStep(checkout, checkout.company.country)
					setCheckout(checkout)
					return checkout.company.country
				})
				.then((country) => {
					setLoading(false)
					handleLocaleChange(language[country], setAppState)
					const billingAddressInputs = country === 'FRA' ? [ 'billing_address.fr' ] : [ 'billing_address' ]
					const shippingAddressInputs = country === 'FRA' ? [ 'shipping_address.fr' ] : [ 'shipping_address' ]
					setAddressInputs({ billingAddressInputs, shippingAddressInputs })
				})
		} catch (e) {
			console.log({ e })
			setLoading(false)

			// if (!e.response) {
			// 	return
			// }

			switch (e.response.status) {
				case 403:
					setMessageError(t.errors['hash.used'])
					break
				case 409:
					setMessageError(t.errors['hash.disabled'])
					break

				default:
					setMessageError(t.errors['hash.server_error'])
					break
			}
		}
	}

	const PlanInfo = ({ planMobile, formikProps }) => {
		const { plan } = checkout
		const coupon = formikProps.values.coupon

		const handleChangeCupom = (values) => {
			formikProps.setFieldValue('coupon', { ...formikProps.values.coupon, ...values })
		}

		const validateCoupon = async () => {
			if (coupon.coupon_code) {
				try {
					const qs = new URLSearchParams(window.location.search)
					const hash = qs.get('hash')
					await billService
						.get(`/checkout/coupons?code=${coupon.coupon_code}`, {
							headers: {
								hash
							}
						})
						.then((res) => {
							const { data: coupon } = res

							if (res.status === 200) {
								handleChangeCupom({ ...coupon })
							}
						})
				} catch (e) {
					handleChangeCupom({ coupon_error: e.response.data.parameterName })
					console.log('ERRO GET COUPOM >>> ', { e })
				}
			} else {
				handleChangeCupom({ coupon_error: 'coupons.field.coupon_code' })
			}
		}

		const Row = ({ label, value }) => (
			<div className="flex-row" style={{ width: '100%', padding: '8px 0' }}>
				<div className="flex-col" style={{ width: '40%', minWidth: 150 }}>
					{label}
				</div>
				<div className="flex-col" style={{ width: '60%', textAlign: 'right' }}>
					{value}
				</div>
			</div>
		)

		const getFrequencyLabel = (frequency) => {
			switch (frequency) {
				case 'DAILY':
					return 'diário.'
				case 'MONTHLY':
					return 'mensal.'
				case 'YEARLY':
					return 'anual.'
				default:
					return ''
			}
		}

		if (plan)
			return (
				<SectionMain
					id={planMobile ? 'plan' : 'plan-card'}
					noPadding
					style={{
						padding: 20,
						width: '100%',
						maxHeight: planHeight + 20,
						maxWidth: planWidth - 50,
						boxShadow: '12px 13px 17px -8px rgba(0,0,0,0.10)',
						border: '2px dashed #DCDCDC',
						backgroundColor: '#fff'
					}}
				>
					{confirm}
					<div ref={(ref) => ref && setPlanHeight(ref.clientHeight)} style={{ padding: 0 }}>
						<SectionMain noPadding>{t.titles['summary.details'].toUpperCase()}</SectionMain>
						<SectionMain>
							{plan.setup_fee > 0 && (
								<React.Fragment>
									<Row
										label={<TitleSmall>{t('input.label.initial_amount').toUpperCase()}</TitleSmall>}
										value={
											<TitleSmall bold>{toCurrency(plan.setup_fee, plan.currency)}</TitleSmall>
										}
									/>
									<div
										className="flex-row center-a center-b"
										style={{ height: 1, border: '1px dashed #DCDCDC', width: '100%' }}
									/>
								</React.Fragment>
							)}

							<Row
								label={<TitleSmall>{plan.name}</TitleSmall>}
								value={
									<React.Fragment>
										<TitleSmall bold align="right">
											{plan.trial.interval > 0 ? (
												`${plan.trial.amount > 0
													? toCurrency(plan.trial.amount, plan.currency)
													: 'Gratuito'} por ${plan.trial.interval} ${plan.trial.interval !== 1
													? t.frequency_quantity[plan.trial.interval_unit]
													: t.frequency_interval_unit[plan.trial.interval_unit]}.`
											) : (
												`${toCurrency(plan.amount, plan.currency)}  ${plan.recurrence.count !==
												1
													? t.frequency[plan.recurrence.frequency]
													: ''}
													`
											)}
										</TitleSmall>
									</React.Fragment>
								}
							/>
							{!plan.trial.interval > 0 && (
								<SectionMain position="flex-end" noPadding>
									<TitleSmall align="right">
										{t(
											'^messages.trial_interval.discount',
											toCurrency(plan.amount),
											t.frequency_interval_unit[plan.recurrence.frequency]
										)}
									</TitleSmall>
								</SectionMain>
							)}
							<SectionMain style={{ paddingTop: 10 }}>
								<TitleSmall align="left">{`${plan.description}`}</TitleSmall>
							</SectionMain>
						</SectionMain>

						<div
							className="flex-row center-a center-b"
							style={{ height: 1, border: '1px dashed #DCDCDC', width: '100%' }}
						/>
						{!payzenForm &&
						checkout.coupon_enabled && (
							<SectionMain>
								{coupon.coupon_id ? (
									<SectionMain
										noPadding
										style={{
											padding: '0px 5px',
											boxShadow: '12px 13px 17px -8px rgba(0,0,0,0.10)',
											border: '2px dashed #90d10e',
											backgroundColor: '#fff'
										}}
									>
										<div
											className="flex-row center-b"
											style={{ width: '100%', alignContent: 'space-between' }}
										>
											<IconButton
												style={{ borderRadius: 5, padding: 2 }}
												color="secondary"
												onClick={() =>
													formikProps.setFieldValue('coupon', { ...coupon, coupon_id: '' })}
											>
												<DeleteIcon />
											</IconButton>
											<Row
												label={
													<span style={{ width: 185 }}>
														<TitleSmall>{coupon.coupon_code}</TitleSmall>
													</span>
												}
												value={
													<TitleSmall bold color="red">
														-{toCurrency(coupon.discount_amount, coupon.currency)}
													</TitleSmall>
												}
											/>
										</div>
									</SectionMain>
								) : (
									<SectionMain noPadding>
										<TextInput
											placeholder={t.titles['coupon.form'].toUpperCase()}
											fullWidth
											errorMessage={coupon.coupon_error}
											onFocus={() => handleChangeCupom({ coupon_error: '' })}
											value={coupon.coupon_code}
											onChange={(e) =>
												handleChangeCupom({
													coupon_code: (e.target.value || '').toUpperCase()
												})}
											rigthElement={
												coupon.coupon_error ? (
													<HtmlTooltip
														open={coupon.coupon_error}
														title={<TitleSmall>{t(coupon.coupon_error)}</TitleSmall>}
														placement="bottom"
													>
														<InfoIcon style={{ color: 'red' }} />
													</HtmlTooltip>
												) : (
													<Button
														onClick={validateCoupon}
														style={{
															backgroundColor: checkout.company.primary_color
																? checkout.company.primary_color
																: backgroundColor
														}}
														variant="contained"
														color="primary"
														size="small"
													>
														{t.buttons['checkout.validate']}
													</Button>
												)
											}
										/>
									</SectionMain>
								)}
								<SectionMain noPadding style={{ paddingTop: 10 }}>
									<TitleSmall>{t.messages['coupon.discount']}</TitleSmall>
								</SectionMain>
							</SectionMain>
						)}
						<span style={{ display: coupon.coupon_id ? 'block' : 'none' }}>
							<div
								className="flex-row center-a center-b"
								style={{ height: 1, border: '1px dashed #DCDCDC', width: '100%' }}
							/>
							{coupon.total_amount > 0 && (
								<Row
									label={<TitleSmall>{t.titles['subtotal.details'].toUpperCase()}</TitleSmall>}
									value={
										<TitleSmall bold color="blue">
											{plan.setup_fee > 0 ? (
												toCurrency(plan.amount + plan.setup_fee, plan.currency)
											) : (
												toCurrency(plan.amount, plan.currency)
											)}
										</TitleSmall>
									}
								/>
							)}
							{coupon.discount_amount && (
								<Row
									label={<TitleSmall>{t.titles['discount_value.details'].toUpperCase()}</TitleSmall>}
									value={
										<TitleSmall bold color="red">
											- {toCurrency(coupon.discount_amount, coupon.currency)}
										</TitleSmall>
									}
								/>
							)}
							{coupon.setup_amount > 0 && (
								<Row
									label={<TitleSmall>{t('input.label.initial_amount').toUpperCase()}</TitleSmall>}
									value={
										<TitleSmall bold>{toCurrency(coupon.setup_amount, coupon.currency)}</TitleSmall>
									}
								/>
							)}
						</span>
						<div
							className="flex-row center-a center-b"
							style={{ height: 1, border: '1px dashed #DCDCDC', width: '100%' }}
						/>

						<SectionMain position="space-between" alignItems="center">
							<TitleSmall fontSize={16}>{t.titles['total.details'].toUpperCase()}</TitleSmall>
							<TitleSmall bold fontSize={20}>
								{coupon.coupon_id ? (
									toCurrency(coupon.total_amount, coupon.currency)
								) : (
									toCurrency(plan.amount, plan.currency)
								)}
							</TitleSmall>
						</SectionMain>
					</div>
				</SectionMain>
			)
		return ''
	}

	const onSubmitForm = async (form, setFieldValue) => {
		const { plan } = checkout

		if (step === 'customer') {
			const formData = {
				customer: {
					first_name: form.first_name,
					last_name: form.last_name,
					email: form.email,
					mobile_number: form.mobile_phone,
					document: form.document ? { ...form.document } : '',
					country_code: form.country_code,
					billing_address: checkout_config.billing_address ? form.billing_address : '',
					shipping_address: checkout_config.shipping_address
						? enabledShippingAddress === true ? [ form.billing_address ] : [ form.shipping_address ]
						: ''
				},
				coupon_id: form.coupon.coupon_id
			}

			if (!checkout.checkout_config.document) {
				delete formData.customer.document
			}
			if (!checkout.checkout_config.country_code) {
				delete formData.customer.country_code
			}
			if (!checkout.checkout_config.shipping_address) {
				delete formData.customer.shipping_address
			}
			if (!checkout.checkout_config.billing_address) {
				delete formData.customer.billing_address
			}
			if (!checkout.coupon_enabled) {
				delete formData.coupon_id
			}

			try {
				setLoading(true)
				const qs = new URLSearchParams(window.location.search)
				const hash = qs.get('hash')

				await billService
					.post(`/checkout?hash=${hash}&step=${checkout.checkout_type}`, formData)
					.then((res) => {
						const { data: { subscription_id, customer_id, form_token, public_key } } = res
						setFieldValue('disabled_input', true)
						setFieldValue('payment_mean', {
							...form.payment_mean,
							subscription_id: subscription_id,
							customer_id: customer_id
						})

						if (checkout.company.use_form_token) {
							setAppState({ payzenForm: { form_token, public_key } })
						}
						setStep('payment')
						setLoading(false)

						setMenuCollapse({ first: false, second: false, third: false, four: true })
					})
			} catch (e) {
				setLoading(false)

				console.log('err', { e })
			}
		}

		if (step === 'payment') {
			const formDataPayment = {
				customer: {
					customer_id: form.payment_mean.customer_id
				},
				subscription_id: form.payment_mean.subscription_id,
				payment_mean: {
					// ...formikProps.values.payment_mean,
					type: 'credit_card',
					number: form.payment_mean.card_number,
					expiry_date: execImmediate(() => {
						try {
							const [ , month, year ] = form.payment_mean.expiry_date.match(/(\d\d)\/(\d\d)/)
							const currYearFirstTwoDigs = new Date().getFullYear().toString().match(/^\d\d/)[0]
							return `${month}/${currYearFirstTwoDigs}${year}`
						} catch (e) {
							return form.payment_mean.expiry_date
						}
					}),
					cvv: form.payment_mean.cvv,
					holder: form.payment_mean.customer_name,
					brand: form.payment_mean.brand
				}
			}

			const response = await showConfirm(t.titles['confirm.modal'], t.messages['confirm.payment'])

			if (response) {
				try {
					setLoading(true)

					const qs = new URLSearchParams(window.location.search)
					const hash = qs.get('hash')

					if (checkout.coupon_enabled) {
						const checkoutResponse = await billService.post(`/checkout?hash=${hash}`, {
							...formDataPayment,
							coupon_id: form.coupon.coupon_id
						})
						setSuccess({
							done: true,
							checkoutResponse,
							userInfo: {
								name: `${form.first_name} ${form.last_name}`,
								email: form.email,
								totalPaid: {
									amount: form.coupon.coupon_id ? form.coupon.total_amount : plan.amount,
									currency: plan.currency
								}
							}
						})

						setLoading(false)
					} else {
						const checkoutResponse = await billService.post(`/checkout?hash=${hash}`, formDataPayment)
						setSuccess({
							done: true,
							sending: false,
							checkoutResponse,
							userInfo: {
								name: `${form.first_name} ${form.last_name}`,
								email: form.email,
								totalPaid: {
									amount: form.coupon.coupon_id ? form.coupon.total_amount : plan.amount,
									currency: plan.currency
								}
							}
						})
						setLoading(false)
					}
				} catch (e) {
					setLoading(false)

					console.log('[ERROR SENDING CHECKOUT]', e)
					// setState({
					// 	showModal: false,
					// 	// done: true,
					// 	sending: false,
					// 	checkoutResponse: e.response || { status: 0, data: {} }
					// })

					setSuccess({
						done: false,
						sending: false,
						checkoutResponse: e.response || { status: 0, data: {} }
					})
				}
			}
		}
	}

	const defaultHandleErrors = () =>
		setTimeout(() => {
			const errorElement = document.getElementsByClassName('error-msg')[0]
			if (!errorElement) {
				return
			}
			errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
		})

	// const validationSchema = () =>
	// 	object().shape({
	// 		first_name: string().required('Preencha o campo nome.'),
	// 		last_name: string().required('Preencha o campo sobrenome.'),
	// 		email: string().email('Digite um e-mail válido').required('Preencha o campo de e-mail'),
	// 		mobile_phone: string().required('Preencha o campo telefone celular.'),

	// 		postal_code: string().required('Preencha o campo cep.'),
	// 		street1: string().required('Preencha o campo rua.'),
	// 		number: string().required('Preencha o campo número.'),

	// 		state: string().required('Preencha o campo estado.'),
	// 		district: string().required('Preencha o campo bairro.'),
	// 		city: string().required('Preencha o campo cidade.'),
	// 		country: string().required('Preencha o campo país.')

	// 		// country_code: string().required('Preencha o campo país.')
	// 	})

	const PageNotFoundCheckout = ({ company }) => {
		const MessageContainer = () => (
			<div className="flex-col center-a center-b">
				<h2 style={{ color: company.style.primary_color, textAlign: 'center' }}>{messageError}</h2>
			</div>
		)

		if (!company) {
			return ''
		}

		return (
			<SectionMain style={{ overflow: 'hidden', height: '60vh' }} noPadding position="center">
				<style>
					{`#banner {
	opacity: 0.5;
	background: ${company.style.primary_color}; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, ${company.style.primary_color}, ${company.style
						.primary_color}66); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, ${company.style.primary_color}, ${company.style.primary_color}66);
}
.MuiBackdrop-root{
    background-color: transparent !important;
}
`}
				</style>
				{/* <div id="banner" className="flex-col" style={{ width: '100%' }}>
					<ParticlesBg
						color={lightOrDark(company.style.primary_color) === 'light' ? '#282828' : '#FFFFFF'}
						type="cobweb"
						// bg={true}
						num={window.innerWidth <= 400 ? 20 : 50}
					/>
				</div> */}

				<SectionMain position="center" noPadding style={{ paddingTop: 100 }}>
					<div
						style={{
							backgroundImage: `url(${company.style.url_logo_default})`,
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							width: '100%',
							height: '10vw',
							maxHeight: 100
						}}
					/>
					<SectionMain position="center" xs={10}>
						<MessageContainer />
					</SectionMain>
				</SectionMain>
			</SectionMain>
		)
	}

	const CheckoutResponse = ({ response, userInfo }) => {
		const [ open, setOpen ] = useState(false)

		useEffect(() => {
			if (response && response.status !== 200) {
				setOpen(true)
			}
		}, [])

		const handleError = () => {
			setSuccess('')
			setOpen(false)
		}

		return (
			<div>
				{response.status === 200 && <SuccessPage userInfo={userInfo} />}

				{response.status !== 200 && (
					<ErrorCheckoutMessage handleError={handleError} errorStatus={response.status} />
				)}
			</div>
		)
	}

	useEffect(() => {
		fetchCheckoutInfo()
		fetchCompanyConfig()
	}, [])

	useEffect(
		() => {
			if (step === 'payment') {
				setValidationSchema(
					object().shape({
						payment_mean: object().shape({
							card_number: string().required('Preencha o campo cep.'),
							cvv: string().required('Preencha o campo rua.'),
							expiry_date: string().required('Preencha o campo número.'),
							customer_name: string().required('Preencha o campo estado.')
						})
					})
				)
			}
		},
		[ step ]
	)

	if (success && success.done) {
		return <CheckoutResponse response={success.checkoutResponse} userInfo={success.userInfo} />
	}

	if (checkout)
		return (
			<Formik
				initialValues={defaultValues}
				validationSchema={() => validationSchema}
				onSubmit={(values, { setFieldValue }) => onSubmitForm(values, setFieldValue)}
			>
				{(formikProps) => (
					<WrapperLayout
						primary_color={checkout.company.primary_color}
						company_name={checkout.company.name}
						logo_white_url={checkout.company.logo_white_url}
					>
						{success && success.done === false && <CheckoutResponse response={success.checkoutResponse} />}
						<SectionMain position="center">
							<Title align="center">{`ASSINATURA - ${checkout.plan.name}`}</Title>
						</SectionMain>
						<Grid xs={12} container justify="space-between">
							<SectionMain xs={12} sm={12} md={7} lg={7} xl={7} style={{ marginTop: 10 }}>
								<form
									autocomplete="new-password"
									action="javascript:void(0)"
									onSubmit={() => {
										defaultHandleErrors()
										if (enabledShippingAddress) {
											setTimeout(() => {
												formikProps.setFieldValue('shipping_address', {
													...formikProps.values.billing_address
												})

												formikProps.handleSubmit()
											})
										} else {
											formikProps.handleSubmit()
										}
										console.log(formikProps.errors)
									}}
								>
									<Hidden only={[ 'lg', 'xl', 'md' ]}>
										<SectionMain xs={12} position="center">
											<PlanInfo formikProps={formikProps} planMobile />
										</SectionMain>
									</Hidden>

									<StepTitle
										iconColor={checkout.company.primary_color}
										handleChange={() => handleMenuCollapsable('first')}
										defaultOpen={menuCollapse.first}
										stepNumber={<PersonIcon style={{ fontSize: 18 }} />}
										stepTitle={t.titles['customer.form']}
									>
										<SectionMain noPadding>
											<RenderInputsV3
												checkoutConfig={checkout.checkout_config}
												formikProps={formikProps}
												arrayInputs={customerInputs}
											/>
										</SectionMain>
									</StepTitle>
									{checkout.checkout_config.billing_address && (
										<StepTitle
											iconColor={checkout.company.primary_color}
											handleChange={() => handleMenuCollapsable('second')}
											defaultOpen={menuCollapse.second}
											stepNumber={<RoomIcon style={{ fontSize: 18 }} />}
											stepTitle={t.titles['billing_address.form']}
										>
											<SectionMain noPadding>
												<RenderInputsV3
													checkoutConfig={checkout.checkout_config}
													formikProps={formikProps}
													arrayInputs={addressInputs.billingAddressInputs}
												/>
											</SectionMain>
										</StepTitle>
									)}
									{checkout.checkout_config.billing_address &&
									checkout.checkout_config.shipping_address && (
										<StepTitle
											iconColor={checkout.company.primary_color}
											handleChange={() => handleMenuCollapsable('third')}
											defaultOpen={menuCollapse.third}
											stepNumber={<LocalShippingIcon style={{ fontSize: 18 }} />}
											stepTitle={t.titles['shipping_address.form']}
										>
											<SectionMain noPadding>
												<SectionMain>
													<FormControlLabel
														onChange={(e) => setEnabledShippingAddress(e.target.checked)}
														control={
															<Checkbox
																color="primary"
																checked={enabledShippingAddress}
																name="enabledShippingAddress"
															/>
														}
														label="Utilizar endereço de cobrança como de entrega."
													/>
												</SectionMain>
												{!enabledShippingAddress && (
													<AnimatedView>
														<RenderInputsV3
															checkoutConfig={checkout.checkout_config}
															formikProps={formikProps}
															arrayInputs={addressInputs.shippingAddressInputs}
														/>
													</AnimatedView>
												)}
											</SectionMain>
										</StepTitle>
									)}
									<StepTitle
										iconColor={checkout.company.primary_color}
										hidden={step !== 'payment' ? true : false}
										// hidden={false}
										handleChange={() => handleMenuCollapsable('four')}
										defaultOpen={menuCollapse.four}
										stepNumber={<CreditCardIcon style={{ fontSize: 18 }} />}
										stepTitle="MEIO DE PAGAMENTO"
									>
										<SectionMain position="center">
											<div id="form-payzen" />
											{checkout.company.use_form_token ? (
												step === 'payment' && (
													<RenderPayzenForm
														gatewayConfig={checkout.gateway_config}
														setSuccess={setSuccess}
														subscriptionId={formikProps.values.payment_mean.subscription_id}
														couponId={formikProps.values.coupon.coupon_id}
													/>
												)
											) : (
												<RenderInputsV3
													checkoutConfig={checkout.checkout_config}
													formikProps={formikProps}
													arrayInputs={paymentInputs}
												/>
											)}
										</SectionMain>
									</StepTitle>
									<SectionMain position="flex-end">
										{step === 'customer' && (
											<Button
												// startIcon={<CircularProgress size={24} />}
												type="submit"
												variant="contained"
												color="primary"
												disabled={formikProps.isSubmitting}
												style={{
													color: '#fff',
													alignItems: 'center',
													backgroundColor: checkout.company.primary_color
														? checkout.company.primary_color
														: backgroundColor
												}}
											>
												{loading && (
													<CircularProgress
														size={24}
														color="inherit"
														style={{ marginRight: 8 }}
													/>
												)}
												Continuar
											</Button>
										)}

										{!payzenForm &&
										step === 'payment' && (
											<Button
												fullWidth
												size="large"
												disabled={loading}
												type="submit"
												variant="contained"
												color="primary"
												style={{
													color: '#fff',
													alignItems: 'center',
													backgroundColor: checkout.company.primary_color
														? checkout.company.primary_color
														: backgroundColor
												}}
											>
												{loading && (
													<CircularProgress
														size={24}
														color="inherit"
														style={{ marginRight: 8 }}
													/>
												)}
												PAGAR
											</Button>
										)}
									</SectionMain>
								</form>
							</SectionMain>
							<Hidden only={[ 'xs', 'sm' ]}>
								<SectionMain
									xs={12}
									md={5}
									lg={5}
									xl={5}
									position="center"
									noPadding
									style={{ paddingTop: 20 }}
								>
									<div
										ref={(ref) => ref && setPlanWidth(ref.clientWidth)}
										className="flex-row center-a"
										style={{
											padding: 0,
											width: '100%'
										}}
									>
										<PlanInfo formikProps={formikProps} />
									</div>
								</SectionMain>
							</Hidden>
						</Grid>
					</WrapperLayout>
				)}
			</Formik>
		)

	if (messageError) {
		return <PageNotFoundCheckout />
	}

	return ''
}

export default TestPage
