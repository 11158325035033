import React from "react";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
} from "@material-ui/core";

const SelectedInput = ({
	label,
	arrayValues = [],
	errorMessage,
	notEmpty,
	firstElement,
	defaultValue,
	...props
}) => {
	return (
		<FormControl variant="outlined" fullWidth error={Boolean(errorMessage)}>
			<InputLabel>{label}</InputLabel>
			<Select defaultValue={defaultValue} label={label} {...props}>
				{!notEmpty && firstElement ? (
					<MenuItem value={firstElement.value}>
						{firstElement.label}
					</MenuItem>
				) : (
					<MenuItem value="">
						<em> </em>
					</MenuItem>
				)}

				{arrayValues.map(({ label, value }) => {
					if (value === firstElement?.value) return
					return (
						<MenuItem key={value} value={value}>{label}</MenuItem>
					)
				})}
			</Select>
			{errorMessage && (
				<span style={{ fontSize: '0.7rem', color: 'red' }}>
					{errorMessage}
				</span>
			)}
		</FormControl>
	);
};

export default SelectedInput;
