import KRGlue from '@lyracom/embedded-form-glue'
import AppContext, { useAppContext } from '../../../AppContextCheckout'
import React, { useEffect, useState } from 'react'
import { billService } from '../../../../core/api'
import Loader from '../../../Loader'
import useMsgBox from '../../../SweetAlert'
import { useTranslation } from '../../../IntlContext/IntlContext'

const RenderPayzenForm = ({
	setSuccess,
	subscriptionId = '',
	couponId = '',
	gatewayConfig = { static_url: 'https://static.payzen.lat' },
	setIsSubmitting = () => null
}) => {
	const msgBox = useMsgBox()
	const t = useTranslation()
	const { language } = useAppContext(AppContext)
	const [isLoading, setIsLoading] = useState(false)
	const { setAppState, payzenForm } = useAppContext(AppContext)
	const renderKRForm = async () => {
		setIsLoading(true)
		try {
			const endpoint = gatewayConfig.static_url
			console.log(endpoint, payzenForm)
			const data = await KRGlue.loadLibrary(endpoint, payzenForm.public_key)
			const { KR } = data
			console.log({ language })
			await KR.setFormConfig({
				formToken: payzenForm.form_token,
				language
			})
				.then(({ KR }) => {
					setIsLoading(false)
					return KR.addForm('#form-payzen')
				}) /* add a payment form  to myPaymentForm div*/
				.then(({ KR, result }) => {
					KR.showForm(result.formId)
				})
			await KR.onSubmit(({ clientAnswer }) => {
				// console.log(clientAnswer)
				if (clientAnswer.orderStatus === 'PAID') {
					setSuccess({
						done: true,
						checkoutResponse: { status: 200 },
						userInfo: {
							name: '',
							email: clientAnswer.customer.email,
							totalPaid: {
								amount: clientAnswer.orderDetails.orderTotalAmount,
								currency: clientAnswer.orderDetails.orderCurrency
							}
						}
					})
				}
			})
			await KR.onError(async (e) => {
				const qs = new URLSearchParams(window.location.search)
				const hash = qs.get('hash')
				if (e.errorCode === 'PSP_108') {
					try {
						await billService
							.get(
								`/checkout/refresh?subscriptionId=${subscriptionId}${couponId &&
								`&couponId=${couponId}`}&hash=${hash}`
							)
							.then((res) => {
								const { data: { form_token, public_key } } = res
								setAppState({ payzenForm: { form_token, public_key } })
							})
						renderKRForm()
					} catch (e) {
						// setAppState({ payzenForm: '' })
						renderKRForm()
					}
				}
			})
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
			// msgBox.err({ text: t.errors.payzen_form_error })
		}
	}

	useEffect(() => {
		renderKRForm()
	}, [])

	if (isLoading) return <Loader />
	return ''
}

export default RenderPayzenForm
