import { Input, TextField } from "@material-ui/core";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import MaskedInput from "react-text-mask";
import { flagReValidators } from "../../../../core/cards-utils";
import generateMask from "../../../../core/generateMask";
import { getviaCepUrl } from "../../../../utils/getViaCepUrl";
import AppContext, { useAppContext } from "../../../AppContextCheckout";
import { useTranslation } from "../../../IntlContext/IntlContext";
import SelectedInput from "../SelectedInput";
import { TextInputMasked } from "../TextInput";
import { handleBrand } from "../../../../utils/handleBrand";
import CardBrand from "../../../../portal/components/cards/CardBrand";
import { currencyLocaleTable } from "../../../../core/toCurrency";
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import CurrencyInput from "react-currency-input"
import MomentUtils from '@date-io/moment'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import formSchema from "../../../../core/formSchema";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pt-br';
import DateInput from "./DateInput";

let documentType
const RenderInputsV4 = ({ rows, formikprops, checkoutConfig, disabledInputs = {} }) => {
    return (
        <div style={{ width: "100%" }}>
            {rows.map((row, index) => (
                <div key={`row-${index}`} className="flex-row xs-wrap">
                    {row.map(input => (
                        <div key={`input-${input}`} className="flex-col" style={{ width: '100%', margin: ['subscription_custom_fields', 'customer_custom_fields'].includes(input) ? "0px 10px 0px 10px" : 10 }}>
                            <InputsSwitch type={input} error={Boolean(formikprops.touched[input] && formikprops.errors[input])} disabled={disabledInputs[input]} required checkoutConfig={checkoutConfig} formikprops={formikprops} />
                            <span style={{ fontSize: '0.7rem', color: 'red' }}>
                                {(formikprops.touched[input] && formikprops.errors[input.replace("_no_mask", "")]) && formikprops.errors[input.replace("_no_mask", "")]}
                            </span>
                        </div>

                    ))}
                </div>
            ))
            }
        </div>
    )
}

const InputsSwitch = ({ type, formikprops, required, error, checkoutConfig = {}, disabled }) => {
    const t = useTranslation()
    let input
    const { lang: language } = useAppContext(AppContext);
    const getDocument = (lang) => {
        return (formSchema[formikprops.values["country_code"] || "BRA"].documentsList).map(elt => (
            { value: elt, label: elt }
        ))

    };


    const getCountry = (lang) => {
        switch (lang) {
            case "pt-BR":
                return "br";

            case "fr":
                return "fr";

            default:
                return "br";
        }
    };

    function handleFocus(event) {
        let lastDigitIndex = 0;
        const { target } = event;
        const { value } = target;
        for (let i = value.length - 1; i >= 0; i--) {
            if (value[i].match(/\d/)) {
                lastDigitIndex = i + 1;
                break;
            }
        }
        setTimeout(() => {
            target.setSelectionRange(lastDigitIndex, lastDigitIndex);
        });
    }

    const handleMobileFocus = (value) => {
        if (value.length <= 12) {
            const el = document.getElementById('input-mobile_phone-1')
            if (document.getElementById('input-mobile_phone') !== document.activeElement) return
            return el.focus()

        } else {
            const el = document.getElementById('input-mobile_phone')
            if (document.getElementById('input-mobile_phone-1') !== document.activeElement) return
            return el.focus()
        }
    }

    const maskType = (type, brand) => {
        switch (type) {
            case "CNPJ":
                return generateMask("99.999.999/9999-99");

            case "CPF":
                return generateMask("999.999.999-99");

            case "CNI":
                return generateMask("99999999999999");

            case "CARD":
                return generateMask(
                    flagReValidators[brand || "default"].raw_mask
                );

            case "CVV":
                return generateMask(
                    flagReValidators[brand || "default"].raw_mask_cvv
                );

            default:
                return generateMask("99999999999999");
                break;
        }
    };

    function TextMaskCustom(props) {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={generateMask('999.999.999-99')}
                placeholderChar={'\u2000'}
            />
        );
    }

    const handleDocumentChange = (e) => {
        const value = e.target.value
        formikprops.handleChange(e)

        if (!formikprops.values['first_name']) return
        documentType = value
        if (value === formSchema[formikprops.values["country_code"]].b2bDocument) {
            const first_name = formikprops.values['first_name']
            const last_name = formikprops.values['last_name']
            formikprops.setFieldValue("first_name", `${first_name}${last_name ? ` ${last_name}` : ""}`)
            formikprops.setFieldValue("last_name", "")

        } else {
            if (!formikprops.values['last_name']) {
                if (formikprops.values['first_name'].includes(" ")) {
                    const first_name = formikprops.values['first_name'].slice(0, formikprops.values['first_name'].indexOf(" "))
                    const last_name = formikprops.values['first_name'].slice(formikprops.values['first_name'].indexOf(" ") + 1, formikprops.values['first_name'].length)
                    formikprops.setFieldValue("first_name", first_name)
                    formikprops.setFieldValue("last_name", last_name)
                } else {
                    const first_name = formikprops.values['first_name']
                    formikprops.setFieldValue("first_name", first_name)
                }
            }
        }

        return
    }

    const handleDocumentBRChange = (e) => {
        const value = e.target.value
        formikprops.handleChange(e)
        if (!formikprops.values['first_name']) return
        documentType = value
        if (value === "CPF") {
            if (!formikprops.values['last_name']) {
                if (formikprops.values['first_name'].includes(" ")) {
                    const first_name = formikprops.values['first_name'].slice(0, formikprops.values['first_name'].indexOf(" "))
                    const last_name = formikprops.values['first_name'].slice(formikprops.values['first_name'].indexOf(" ") + 1, formikprops.values['first_name'].length)
                    formikprops.setFieldValue("first_name", first_name)
                    formikprops.setFieldValue("last_name", last_name)
                } else {
                    const first_name = formikprops.values['first_name']
                    formikprops.setFieldValue("first_name", first_name)
                }
            }

        } else if (value === "CNPJ") {
            const first_name = formikprops.values['first_name']
            const last_name = formikprops.values['last_name']

            formikprops.setFieldValue("first_name", `${first_name}${last_name ? ` ${last_name}` : ""}`)
            formikprops.setFieldValue("last_name", "")
        }
        return
    }

    // TextMaskCustom.propTypes = {
    //     inputRef: PropTypes.func.isRequired,
    // };

    switch (type) {
        case 'first_name':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.first_name}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />
            break;

        case 'last_name':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.last_name}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'document_type':

            const getDocumentTypeInput = () => {
                const country = checkoutConfig?.company.address.country_code
                let input
                switch (country) {
                    case 'BRA':
                        input = <SelectedInput
                            disabled={disabled}
                            value={formikprops.values[type]}
                            name={type}
                            inputProps={{ 'data-cy': `cy-input-${type}` }}
                            defaultValue={getDocument(country)[0].value}
                            onChange={handleDocumentBRChange}
                            arrayValues={getDocument(country)}
                            label={`*${t("^forms.document_type",)}`}
                            onBlur={formikprops.handleBlur}
                            errorMessage={
                                formikprops.touched[type] &&
                                formikprops.errors[type]
                            }
                        />
                        break;

                    case 'FRA':
                        input = <SelectedInput
                            disabled={disabled}
                            value={formikprops.values[type]}
                            name={type}
                            inputProps={{ 'data-cy': `cy-input-${type}` }}
                            defaultValue="CNI"
                            onChange={e => {
                                const value = e.target.value
                                formikprops.setFieldValue("document_type", value)
                                documentType = value
                                return
                            }}
                            arrayValues={getDocument(country)}
                            label={t("^forms.document_type", "*")}
                            onBlur={formikprops.handleBlur}
                            errorMessage={
                                formikprops.touched[type] &&
                                formikprops.errors[type]
                            }
                        />
                        break;

                    default:
                        input = <SelectedInput
                            disabled={disabled}
                            value={formikprops.values[type]}
                            name={type}
                            defaultValue={checkoutConfig.documents_list[0]}
                            inputProps={{ 'data-cy': `cy-input-${type}` }}
                            onChange={handleDocumentChange}
                            arrayValues={getDocument(country)}
                            label={`*${t("^forms.document_type",)}`}
                            onBlur={formikprops.handleBlur}
                            errorMessage={
                                formikprops.touched[type] &&
                                formikprops.errors[type]
                            }
                        />
                        break;
                }
                return input
            }

            documentType = formikprops.values[type]

            input = getDocumentTypeInput()

            break;

        case 'country_code':
            input = <SelectedInput
                disabled={disabled}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }}
                value={formikprops.values["country_code"]}
                onChange={e => {
                    const value = e.target.value
                    formikprops.handleChange(e)
                    formikprops.setCustomerCountry(value)
                    formikprops.setFieldValue("document_type", checkoutConfig.checkout_config.checkout_fields.prioritize_b2b ? formSchema[value].b2bDocument : formSchema[value].documentsList[0])
                    return
                }}
                onBlur={formikprops.handleBlur}
                firstElement={formSchema[checkoutConfig?.company.address.country_code].defaultCountryOption}
                arrayValues={
                    [
                        { value: "FRA", label: "France" },
                        { value: "BRA", label: "Brasil" },
                        { value: "PER", label: "Perú" },
                        { value: "ARG", label: "Argentina" },
                        { value: "COL", label: "Colombia" },
                        { value: "CHL", label: "Chile" },
                    ]
                }
                label={t("^forms.country")}
                errorMessage={
                    formikprops.touched[type] &&
                    formikprops.errors[type]
                }
            />

            break;

        case 'document_type_fr':
            documentType = formikprops.values[type]


            break;

        case 'document_number':
            input = <MaskedInput
                disabled={disabled}
                errorMessage={error}
                autoComplete="on"
                onBlur={formikprops.handleBlur}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }}
                value={
                    formikprops.values[type]
                }
                onFocus={handleFocus}
                onChange={formikprops.handleChange}
                mask={() => {
                    return maskType(documentType);
                }}
                placeholderChar={"\u2000"}
                render={(ref, props) => (
                    <TextInputMasked
                        id="form-input-section"
                        inputPro
                        shrink={true}
                        type="tel"
                        labelProps={{ shrink: true }}
                        label={`*${t(
                            "^forms.document_number",
                            "*"
                        )}`}
                        inputRef={ref}
                        {...props}
                    />
                )}
            />



            break;

        case 'email':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.email}`} type="email"
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'state_tax_number':
            input = <MaskedInput
                disabled={disabled}
                name={type}
                type="text"
                onBlur={(e) => {
                    formikprops.handleBlur(e)
                }
                }
                errorMessage={error}
                value={formikprops.values[type]}
                onChange={(e) => {
                    const number = (
                        e.target.value)
                    formikprops.setFieldValue(`state_tax_number`, number);
                }}
                mask={() =>
                    generateMask('99999999999999999999')
                }
                placeholderChar={"\u2000"}
                render={(ref, props) => (
                    <TextInputMasked
                        inputProps={{ 'data-cy': `cy-input-${type}` }}
                        label={t("@@ Inscrição estadual")}
                        inputRef={ref}
                        {...props}
                    />
                )}
            />
            break;
        case 'city_tax_number':
            input = <MaskedInput
                disabled={disabled}
                name={type}
                type="text"
                onBlur={(e) => {
                    formikprops.handleBlur(e)
                }
                }
                errorMessage={error}
                value={formikprops.values[type]}
                onChange={(e) => {
                    const number = (
                        e.target.value)
                    formikprops.setFieldValue(`city_tax_number`, number);
                }}
                mask={() =>
                    generateMask('99999999999999999999')
                }
                placeholderChar={"\u2000"}
                render={(ref, props) => (
                    <TextInputMasked
                        inputProps={{ 'data-cy': `cy-input-${type}` }}
                        label={t("@@ Inscrição Municipal")}
                        inputRef={ref}
                        {...props}
                    />
                )}
            />
            break;
        case 'mobile_phone':

            const countries = {
                'CHL': 'cl',
                'USA': 'us',
                'BRA': 'br',
                'COL': 'co',
                'PER': 'pe',
                'CAN': 'ca',
                'ARG': 'ar',
            }

            const country = checkoutConfig?.company.address.country_code

            //Gambiarra pra deixar a mascara "dinâmica"

            input = <div className="flex-row">
                <div style={{
                    width: formikprops.values.mobile_phone.length > 12 ? '0px' : '100%',
                    overflow: formikprops.values.mobile_phone.length > 12 && "hidden",
                    // margin: "-5px",
                    // padding: 5,
                    opacity: formikprops.values.mobile_phone.length > 12 && 0
                }}
                >
                    <PhoneInput fullWidth
                        disabled={disabled}
                        key={`input-${type}-1`}
                        InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                        masks={{ br: '(..) ....-.....' }}
                        inputStyle={{ borderColor: error && "#f44336" }}
                        error={error}
                        onBlur={formikprops.handleBlur}
                        style={{ width: formikprops.values.mobile_phone.length > 12 && 0 }}
                        onChange={(value) => {
                            formikprops.setFieldValue(
                                type,
                                value
                            );
                            handleMobileFocus(value)
                        }}
                        inputProps={{ name: type, id: `input-${type}-1`, autoComplete: 'off', 'data-cy': `cy-input-${type}` }}
                        value={formikprops.values[type]}
                        specialLabel={`${required && "*"}${t.forms.mobile_phone}`}
                        variant="outlined"
                        country={countries[formikprops.values['country_code']]} />
                </div>
                <div style={{
                    width: formikprops.values.mobile_phone.length <= 12 ? '0px' : '100%',
                    overflow: formikprops.values.mobile_phone.length <= 12 && "hidden",
                    // margin: "-5px",
                    // padding: 5,
                    opacity: formikprops.values.mobile_phone.length <= 12 && 0
                }}
                >
                    <PhoneInput fullWidth
                        disabled={disabled}
                        key={`input-${type}`}
                        InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                        masks={{ br: '(..) . ....-....' }}
                        inputStyle={{ borderColor: error && "#f44336" }}
                        error={error}
                        onBlur={formikprops.handleBlur}
                        onChange={(value) => {
                            formikprops.setFieldValue(
                                type,
                                value
                            );
                            handleMobileFocus(value)
                        }}
                        inputProps={{ name: type, id: `input-${type}`, autoComplete: 'off' }}
                        value={formikprops.values[type]}
                        specialLabel={`${required && "*"}${t.forms.mobile_phone}`}
                        variant="outlined"
                        country={countries[country]} />

                </div>
            </div>
            //Sorry not sorry
            break;

        case 'billing_address_postal_code':
            input = <MaskedInput
                disabled={disabled}
                errorMessage={error}
                value={formikprops.values[type]}
                onFocus={handleFocus}
                onChange={(e) => {
                    const value = (
                        e.target.value.toString().match(/\d/g) || []
                    ).join("");
                    formikprops.setFieldValue(`${type}`, value);
                }}
                mask={() => generateMask("99999-999")}
                placeholderChar={"\u2000"}
                onBlur={e => {
                    const value = (
                        e.target.value.toString().match(/\d/g) || []
                    ).join("");
                    getviaCepUrl(value, formikprops, 'billing_address');

                }}
                autoComplete="postal-code"
                render={(ref, props) => (
                    <TextInputMasked
                        type="tel"
                        inputProps={{ 'data-cy': `cy-input-${'billing_address_postal_code'}` }}
                        label={`${required && "*"}${t("^forms.postal_code")}`}
                        inputRef={ref}
                        {...props}
                    />
                )}
            />

            break;

        case 'billing_address_postal_code_no_mask':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${'billing_address_postal_code'}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values['billing_address_postal_code']) }}
                error={formikprops.errors['billing_address_postal_code']}
                autoComplete="number"
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values['billing_address_postal_code']}
                label={`${required && "*"}${t("^forms.postal_code")}`}
                name={'billing_address_postal_code'}
                inputProps={{ 'data-cy': `cy-input-${'billing_address_postal_code'}` }} variant="outlined" />

            break;

        case 'billing_address_postal_code_fr':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                autoComplete="number"
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t("^forms.postal_code")}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'billing_address_street1':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t("^forms.street", "")}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'billing_address_street2':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${t("^forms.street", "", "2")}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'billing_address_number':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                autoComplete="number"
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.street_number}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'billing_address_state':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.state}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'billing_address_state_br':
            input = <SelectedInput
                disabled={disabled}
                name={"billing_address_state"}
                value={formikprops.values["billing_address_state"]}
                onChange={e => {
                    formikprops.handleChange(e)
                    return
                }}
                onBlur={formikprops.handleBlur}
                arrayValues={
                    [
                        { value: 'AC', label: 'AC' },
                        { value: 'AM', label: 'AM' },
                        { value: 'AL', label: 'AL' },
                        { value: 'AP', label: 'AP' },
                        { value: 'BA', label: 'BA' },
                        { value: 'CE', label: 'CE' },
                        { value: 'DF', label: 'DF' },
                        { value: 'ES', label: 'ES' },
                        { value: 'GO', label: 'GO' },
                        { value: 'MA', label: 'MA' },
                        { value: 'MG', label: 'MG' },
                        { value: 'MS', label: 'MS' },
                        { value: 'MT', label: 'MT' },
                        { value: 'PA', label: 'PA' },
                        { value: 'PB', label: 'PB' },
                        { value: 'PE', label: 'PE' },
                        { value: 'PI', label: 'PI' },
                        { value: 'PR', label: 'PR' },
                        { value: 'RJ', label: 'RJ' },
                        { value: 'RN', label: 'RN' },
                        { value: 'RO', label: 'RO' },
                        { value: 'RR', label: 'RR' },
                        { value: 'RS', label: 'RS' },
                        { value: 'SC', label: 'SC' },
                        { value: 'SE', label: 'SE' },
                        { value: 'SP', label: 'SP' },
                        { value: 'TO', label: 'TO' },
                    ]
                }
                label={t("^forms.state")}
                errorMessage={
                    formikprops.touched['billing_address_state'] &&
                    formikprops.errors['billing_address_state']
                }
            />

            break;


        case 'billing_address_district':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.district}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'billing_address_city':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.city}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'billing_address_country':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.country}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'billing_address_complement':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${t.forms.complement}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_postal_code':
            input = <MaskedInput
                disabled={disabled}
                errorMessage={error}
                value={formikprops.values[type]}
                onFocus={handleFocus}
                onChange={(e) => {
                    const value = (
                        e.target.value.toString().match(/\d/g) || []
                    ).join("");
                    getviaCepUrl(value, formikprops, 'shipping_address');
                    formikprops.setFieldValue(`${type}`, value);
                }}
                mask={() => generateMask("99999-999")}
                placeholderChar={"\u2000"}

                autoComplete="postal-code"
                render={(ref, props) => (
                    <TextInputMasked
                        type="tel"
                        label={t("^forms.postal_code", "*")}
                        inputRef={ref}
                        inputProps={{ 'data-cy': `cy-input-${'shipping_address_postal_code'}` }}
                        {...props}
                    />
                )}
            />

            break;

        case 'shipping_address_postal_code_no_mask':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${'shipping_address_postal_code'}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values['shipping_address_postal_code']) }}
                error={formikprops.errors['shipping_address_postal_code']}
                label={t("^forms.postal_code", "*")}
                autoComplete="number"
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values['shipping_address_postal_code']}
                name={'shipping_address_postal_code'}
                inputProps={{ 'data-cy': `cy-input-${'shipping_address_postal_code'}` }} variant="outlined" />

            break;
        case 'shipping_address_postal_code_fr':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                label={t("^forms.postal_code", "*")}
                autoComplete="number"
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_street1':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t("^forms.street", "")}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_street2':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${t("^forms.street", "", "2")}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_number':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                autoComplete="number"
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.street_number}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_state':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.state}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_state_br':
            input = <SelectedInput
                disabled={disabled}
                name={"shipping_address_state"}
                value={formikprops.values["shipping_address_state"]}
                onChange={e => {
                    formikprops.handleChange(e)
                    return
                }}
                onBlur={formikprops.handleBlur}
                arrayValues={
                    [
                        { value: 'AC', label: 'AC' },
                        { value: 'AM', label: 'AM' },
                        { value: 'AL', label: 'AL' },
                        { value: 'AP', label: 'AP' },
                        { value: 'BA', label: 'BA' },
                        { value: 'CE', label: 'CE' },
                        { value: 'DF', label: 'DF' },
                        { value: 'ES', label: 'ES' },
                        { value: 'GO', label: 'GO' },
                        { value: 'MA', label: 'MA' },
                        { value: 'MG', label: 'MG' },
                        { value: 'MS', label: 'MS' },
                        { value: 'MT', label: 'MT' },
                        { value: 'PA', label: 'PA' },
                        { value: 'PB', label: 'PB' },
                        { value: 'PE', label: 'PE' },
                        { value: 'PI', label: 'PI' },
                        { value: 'PR', label: 'PR' },
                        { value: 'RJ', label: 'RJ' },
                        { value: 'RN', label: 'RN' },
                        { value: 'RO', label: 'RO' },
                        { value: 'RR', label: 'RR' },
                        { value: 'RS', label: 'RS' },
                        { value: 'SC', label: 'SC' },
                        { value: 'SE', label: 'SE' },
                        { value: 'SP', label: 'SP' },
                        { value: 'TO', label: 'TO' },
                    ]
                }
                label={t("^forms.state")}
                errorMessage={
                    formikprops.touched['shipping_address_state'] &&
                    formikprops.errors['shipping_address_state']
                }
            />

            break;

        case 'shipping_address_district':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.district}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_city':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.city}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_country':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${required && "*"}${t.forms.country}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'shipping_address_complement':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${t.forms.complement}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }} variant="outlined" />

            break;

        case 'cvv':
            input = <MaskedInput
                disabled={disabled}
                errorMessage={error}
                autoComplete="cc-csc"
                onBlur={formikprops.handleBlur}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }}
                value={
                    formikprops.values[type]
                }
                onFocus={handleFocus}
                onChange={(e) => {
                    const value = (
                        e.target.value.toString().match(/\d/g) || []
                    ).join("");
                    formikprops.setFieldValue(`${type}`, value);
                }}
                mask={() => {
                    return maskType("CVV", formikprops.values.cc_brand, formikprops);
                }}
                placeholderChar={"\u2000"}
                render={(ref, props) => (
                    <TextInputMasked
                        labelProps={{ shrink: Boolean(formikprops.values[type]) }}
                        id="form-input-section"
                        type="tel"
                        pattern="\d*"
                        label={t(
                            "^forms.cvv",
                            "*"
                        )}
                        inputRef={ref}
                        {...props}
                    />
                )}
            />

            break;

        case 'cc_number':
            input = <MaskedInput
                disabled={disabled}
                name="cnumeric"
                type="text"
                // onFocus={handleFocus}
                onBlur={(e) => {
                    formikprops.handleBlur(e)
                    handleBrand(
                        formikprops.values[type],
                        formikprops
                    )
                }
                }
                errorMessage={error}
                value={formikprops.values[type]}
                onChange={(e) => {
                    const number = (
                        e.target.value.match(/\d/g) || []
                    ).join("");
                    formikprops.setFieldValue(`cc_number`, number);
                }}
                mask={() =>
                    maskType(
                        "CARD",
                        formikprops.values.brand
                    )
                }
                placeholderChar={"\u2000"}
                render={(ref, props) => (
                    <TextInputMasked
                        label={t("^forms.card_number", "*")}
                        labelProps={{ shrink: Boolean(formikprops.values[type]) }}
                        autoComplete="cc-number"
                        inputRef={ref}
                        pattern="\d*"
                        inputProps={{ 'data-cy': `cy-input-${type}` }}
                        rigthElement={
                            <div
                                style={{ paddingBottom: "5px" }}
                                className="flex-row center-a xs-disappear"
                            >
                                {checkoutConfig.checkout_config?.brand_list?.length > 0 && checkoutConfig.checkout_config?.brand_list?.map((brand) => (
                                    <CardBrand
                                        cardBrand={
                                            formikprops.values.cc_brand
                                        }
                                        brand={brand}
                                    />
                                ))}
                            </div>
                        }
                        {...props}
                    />
                )}
            />

            break;

        case 'cc_expiry_date':
            input = <MaskedInput
                disabled={disabled}
                name="cnumeric"
                type="text"
                // onFocus={handleFocus}
                onBlur={(e) => {
                    formikprops.handleBlur(e)
                }
                }
                errorMessage={error}
                value={formikprops.values[type]}
                onChange={(e) => {
                    const number = (
                        e.target.value)
                    formikprops.setFieldValue(`cc_expiry_date`, number);
                }}
                placeholder="MM/YY"

                mask={() =>
                    generateMask('99/99')
                }
                placeholderChar={"\u2000"}
                render={(ref, props) => (
                    <TextInputMasked
                        labelProps={{ shrink: Boolean(formikprops.values[type]) }}
                        inputProps={{ 'data-cy': `cy-input-${type}` }}
                        label={t("^forms.expiry_date", "*")}
                        autoComplete="cc-exp"
                        inputRef={ref}
                        {...props}
                    />
                )}
            />

            break;

        case 'cc_cardholder':
            input = <TextField fullWidth
                disabled={disabled}
                key={`input-${type}`}
                autoComplete="cc-name"
                InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[type]}
                label={`${t.forms.customer_name}`}
                name={type}
                inputProps={{ 'data-cy': `cy-input-${type}` }}
                variant="outlined"
            />

            break;

        case 'birth_date':
            input =
                <DateInput
                    fullWidth
                    disabled={disabled}
                    disableFuture
                    key={`input-${type}`}
                    InputLabelProps={{ shrink: Boolean(formikprops.values[type]) }}
                    error={error}
                    onBlur={formikprops.handleBlur}
                    onChange={(newValue) => {
                        formikprops.setFieldValue(type, newValue)
                    }}
                    value={formikprops.values[type]}
                    label={`${required && "*"}${"Data de nascimento"}`}
                    name={type}
                    inputProps={{
                        'data-cy': `cy-input-${type}`,
                    }}
                    variant="outlined"
                    country={checkoutConfig?.company.address.country_code} />

            break;

        case 'customer_custom_fields':
            input = <div style={{ width: "100%" }}>
                <div key={`input-${input}`} className="flex-col" style={{ width: '100%' }}>
                    {checkoutConfig.checkout_config.customer_custom_fields?.map(field => {
                        if (!field.is_enabled) return
                        return (
                            <div key={field.key} style={{ marginBottom: 10, marginTop: 10 }}>
                                <CustomFieldRenderer field={field} formikprops={formikprops} error={Boolean(formikprops.touched[`customer_${field.key}`] && formikprops.errors[`customer_${field.key}`])} type={'customer'} checkoutConfig={checkoutConfig} />
                                <span style={{ fontSize: '0.7rem', color: 'red' }}>
                                    {(formikprops.touched[`customer_${field.key}`] && formikprops.errors[`customer_${field.key}`]) && formikprops.errors[`customer_${field.key}`]}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>

            break;

        case 'subscription_custom_fields':
            input = <div style={{ width: "100%" }}>
                <div key={`input-${input}`} className="flex-col" style={{ width: '100%' }}>
                    {checkoutConfig.checkout_config.subscription_custom_fields?.map(field => {
                        if (!field.is_enabled) return
                        return (
                            <div key={field.key} style={{ marginBottom: 10, marginTop: 10 }}>
                                <CustomFieldRenderer field={field} formikprops={formikprops} error={Boolean(formikprops.touched[`subscription_${field.key}`] && formikprops.errors[`subscription_${field.key}`])} type={'subscription'} checkoutConfig={checkoutConfig} />
                                <span style={{ fontSize: '0.7rem', color: 'red' }}>
                                    {(formikprops.touched[`subscription_${field.key}`] && formikprops.errors[`subscription_${field.key}`]) && formikprops.errors[`subscription_${field.key}`]}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>

            break;

        case 'coupon':
            input = <TextField fullWidth variant="outlined" label="Código do cupom"></TextField>
            break;

        default:
            input = null
            break;
    }
    return input
}

export default RenderInputsV4


const CustomFieldRenderer = ({ field, formikprops, error, checkoutConfig, type = "" }) => {

    let input
    const t = useTranslation()

    const name = `${type}_${field.key}`

    const country = checkoutConfig?.company.address.country_code

    switch (field.type) {
        case 'text':
            input = <TextField fullWidth
                key={`input-${name}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[name]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={formikprops.handleChange}
                value={formikprops.values[name]}
                label={`${field.is_required ? "*" : ""}${field.label}`}
                name={name}
                inputProps={{ 'data-cy': `cy-input-${name}`, maxlength: "200" }} variant="outlined" />
            break;

        case 'number':
            input = <TextField
                label={`${field.is_required ? "*" : ""}${field.label}`}
                value={formikprops.values[name]}
                onChange={formikprops.handleChange}
                name={name}
                key={name}
                variant="outlined"
                fullWidth
                // defaultValue={0}
                country={country}
                inputProps={{ 'data-cy': `cy-input-${name}`, maxlength: "200" }}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
            />
            break;

        case 'money':

            const handleChange = (e) => {

                const value = e.target.value
                formikprops.setFieldValue(name, value)

            }

            const parts = new Intl.NumberFormat(currencyLocaleTable[country], { style: 'currency', currency: checkoutConfig.plan.currency }).formatToParts(1)

            const symbol = parts.find(p => p.type === "currency").value

            input = <TextField
                label={`${field.is_required ? "*" : ""}${field.label}`}
                value={formikprops.values[name]}
                onChange={handleChange}
                name={name}
                key={name}
                error={error}
                variant="outlined"
                fullWidth
                // defaultValue={0}
                inputProps={{ 'data-cy': `cy-input-${name}`, maxlength: "200" }}
                country={country}
                InputProps={{
                    inputComponent: CurrencyFormatCustom,
                    startAdornment: country === 'FRA' ? '' : symbol,
                    endAdornment: country === 'FRA' ? '€' : ""
                }}
            />

            break;

        case 'select':
            input = <SelectedInput
                name={name}
                value={formikprops.values[name]}
                error={error}
                onChange={e => {
                    formikprops.handleChange(e)
                    return
                }}
                onBlur={formikprops.handleBlur}
                arrayValues={
                    field.values.map(option => (
                        { value: option.value, label: option.label }
                    ))
                }
                label={`${field.is_required ? "*" : ""}${field.label}`}
            />
            break;

        case 'date':
            input = <DateInput
                fullWidth
                key={`input-${name}`}
                InputLabelProps={{ shrink: Boolean(formikprops.values[name]) }}
                error={error}
                onBlur={formikprops.handleBlur}
                onChange={(newValue) => {
                    formikprops.setFieldValue(name, newValue)
                }}
                value={formikprops.values[name]}
                label={`${field.is_required ? "*" : ""}${field.label}`}
                name={name}
                inputProps={{ 'data-cy': `cy-input-${name}`, maxlength: "200" }} variant="outlined"
                country={checkoutConfig?.company.address.country_code} />
            break;


        default: return null;
    }
    return input

}



function CurrencyFormatCustom(props) {
    const { inputRef, onChange, country, ...other } = props;

    return (
        <CurrencyInput
            {...other}
            onChangeEvent={onChange}
            precision={country === "CHL" ? 0 : 2}
            onFocus={(event) => {
                /** @type {HTMLInputElement} */
                const input = event.target
                /** @type {string} */
                const currentValue = event.target.value
                let pos = currentValue.length
                setTimeout(() => {
                    input.setSelectionRange(pos, pos)
                })
            }}
            ref={(ref) => inputRef(ref ? ref.theInput : null)}
        />
    );
}

CurrencyFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChangeEvent: PropTypes.func.isRequired,
};


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
            decimalScale={0}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};